import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'

const ArticleFooter = ({ author }) => {
  return author?.document?.data ? (
    <div className="lg:hidden small-container">
      <div className="my-24 mx-auto p-6 rounded-2xl bg-gray bg-opacity-50 font-sans text-xs">
        <RichText
          htmlSerializer={htmlSerializer}
          render={author?.document?.data?.author_bio?.raw}
        />
        <TransitionLink
          to={linkResolver(author?.document)}
          entry={pageTransitionIn}
          exit={pageTransitionOut}
        >
          Discover more from {author?.document?.data?.author_name?.text}
        </TransitionLink>.
      </div>
    </div>
  ) : null
}

export default ArticleFooter
export { ArticleFooter }